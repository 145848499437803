import MailIcon from "@material-ui/icons/Mail";
import HomeIcon from "@material-ui/icons/Home";
// import PhotoLibrary from "@material-ui/icons/PhotoLibrary";
import FacebookIcon from "@material-ui/icons/Facebook";
import InfoIcon from "@material-ui/icons/Info";
// import RoomService from "@material-ui/icons/RoomService";
// import InvertColors from "@material-ui/icons/InvertColors";
// import FormatPaint from "@material-ui/icons/FormatPaint";
// import HomeWork from "@material-ui/icons/HomeWork";
// import Kitchen from "@material-ui/icons/Kitchen";
// import Build from "@material-ui/icons/Build";
// import Bathtub from "@material-ui/icons/Bathtub";
import { SvgIconProps } from "@material-ui/core";
import hero from "./images/heroTall.jpg";
import bathrooms from "./images/services/bathrooms.jpg";
import renovations from "./images/services/rennovations.jpg";

export interface NavConfig {
  path: string;
  text: string;
  Icon: React.ComponentType<SvgIconProps>;
  children?: NavConfig[];
}

export const navConfig: NavConfig[] = [
  { text: "HOME", Icon: HomeIcon, path: "/" },
  { text: "ABOUT US", Icon: InfoIcon, path: "/about" },
  // {
  //   text: "SERVICES",
  //   Icon: RoomService,
  //   path: "/services",
  //   children: [
  //     {
  //       text: "RENOVATIONS",
  //       Icon: FormatPaint,
  //       path: "/services/renovations",
  //     },
  //     {
  //       text: "BATHROOMS",
  //       Icon: Bathtub,
  //       path: "/services/bathrooms",
  //     },
  //     {
  //       text: "MAINTENANCE",
  //       Icon: Build,
  //       path: "/services/maintenance",
  //     },
  //     {
  //       text: "EXTENSIONS",
  //       Icon: HomeWork,
  //       path: "/services/extensions",
  //     },
  //     { text: "KITCHENS", Icon: Kitchen, path: "/services/kitchens" },
  //     {
  //       text: "PLUMBING",
  //       Icon: InvertColors,
  //       path: "/services/plumbing",
  //     },
  //   ],
  // },
  // { text: "GALLERY", Icon: PhotoLibrary, path: "/gallery" },
  { text: "CONTACT", Icon: MailIcon, path: "/contact" },
  // {
  //   text: "FIND US ON FACEBOOK",
  //   Icon: FacebookIcon,
  //   path: "https://www.facebook.com/PE-Building-Solutions-108118184107282/",
  // },
];

export interface ServiceConfig {
  title: string;
  path: string;
  hero: string;
  about: string;
}

export const servicesConfig: ServiceConfig[] = [
  {
    title: "RENOVATIONS",
    path: "/services/renovations",
    hero: renovations,
    about: "",
  },
  {
    title: "BATHROOMS",
    path: "/services/bathrooms",
    hero: bathrooms,
    about: "",
  },
  {
    title: "MAINTENANCE",
    path: "/services/maintenance",
    hero: hero,
    about: "",
  },
  {
    title: "EXTENSIONS",
    path: "/services/extensions",
    hero: hero,
    about: "",
  },
  {
    title: "KITCHENS",
    path: "/services/kitchens",
    hero: hero,
    about: "",
  },
  {
    title: "PLUMBING",
    path: "/services/plumbing",
    hero: hero,
    about: "",
  },
];

const serviceHighlightTitles: string[] = [
  "EXTENSIONS",
  "BATHROOMS",
  "RENOVATIONS",
];

export const getDefaultService: () => string = () => {
  return servicesConfig[0].title;
};

export const getServices: () => ServiceConfig[] = () => {
  return servicesConfig;
};

export const getServiceConfig: (name: string) => ServiceConfig = (
  name: string
) => {
  return (
    servicesConfig.find((x) => x.title === name.toUpperCase()) ||
    servicesConfig[0]
  );
};

export const getServiceHighlights: () => ServiceConfig[] = () => {
  return servicesConfig.filter((config) =>
    serviceHighlightTitles.includes(config.title)
  );
};

export const getNavConfig: (except?: string) => NavConfig[] = (
  except?: string
) => {
  if (except) {
    return navConfig.filter(
      (config) => config.text.toUpperCase() !== except.toUpperCase()
    );
  } else {
    return navConfig;
  }
};
