import React from "react";
import { makeStyles, Grid, Paper, Typography } from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import { servicesConfig, ServiceConfig } from "../config";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { GetQuoteButton } from "./actionButtons";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.text.primary,
    padding: theme.spacing(6),
  },
  container: {
    flexGrow: 1,
    paddingTop: "20px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  quoteContainer: {
    display: "flex",
  },
  quote: {
    flexGrow: 1,
  },
  about: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  link: {
    cursor: "pointer",
  },
}));

interface ServiceTeaserProps extends RouteComponentProps<{}> {}

const ServiceTeaser: React.FC<ServiceTeaserProps> = () => {
  const classes = useStyles();

  const ServiceItem: React.FC<ServiceConfig> = ({ title, about, path }) => {
    return (
      <Grid item xs={12} sm={4}>
        <Paper className={classes.paper}>
          <StarIcon />
          <Typography variant="h4">{title}</Typography>
          <Typography className={classes.about}>{about}</Typography>
        </Paper>
      </Grid>
    );
  };

  const servicesRow1 = servicesConfig
    .slice(0, 3)
    .map((config, i) => <ServiceItem {...config} key={`service_1_${i}`} />);

  const servicesRow2 = servicesConfig
    .slice(3, 6)
    .map((config, i) => <ServiceItem {...config} key={`service_2_${i}`} />);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Grid container spacing={6}>
          {servicesRow1}
        </Grid>
      </div>

      <div className={classes.container}>
        <Grid container spacing={6}>
          {servicesRow2}
        </Grid>
      </div>

      <div className={classes.container}>
        <div className={classes.quoteContainer}>
          <GetQuoteButton />
        </div>
      </div>
    </div>
  );
};

export default withRouter(ServiceTeaser);
