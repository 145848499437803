import React from "react";
import { makeStyles, Grid, Avatar, Typography, Link } from "@material-ui/core";
import logo from "../images/logo.png";
import HomeIcon from "@material-ui/icons/Home";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#333333",
    padding: theme.spacing(3),
  },
  container: {
    marginLeft: "10%",
    marginRight: "10%",
  },
  fill: { flexGrow: 1 },
  logo: {},
  contact: {},
}));

const Footer: React.FC<{}> = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <div className={classes.logo}>
              <img src={logo} alt="logo" />
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container item spacing={3}>
              <Grid item>
                <Avatar>
                  <HomeIcon />
                </Avatar>
              </Grid>
              <Grid item>
                <Typography noWrap>
                  Based in
                  <br />
                  Walton-on-Thames
                  <br />
                  Surrey
                </Typography>
              </Grid>
            </Grid>

            <Grid container item spacing={3}>
              <Grid item>
                <Avatar>
                  <EmailIcon />
                </Avatar>
              </Grid>
              <Grid item>
                <Typography noWrap>
                  <Link
                    underline="hover"
                    href="mailto:paul@pebuildingsolutions.co.uk"
                    color="textPrimary"
                  >
                    paul@pebuildingsolutions.co.uk
                  </Link>
                </Typography>
              </Grid>
            </Grid>

            <Grid container item spacing={3}>
              <Grid item>
                <Avatar>
                  <PhoneIcon />
                </Avatar>
              </Grid>
              <Grid item>
                <Link
                  color="textPrimary"
                  underline="hover"
                  href="tel:+447896496293"
                >
                  07896 496 293
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Footer;
