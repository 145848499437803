import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
  Hidden,
  makeStyles,
  Link as MuiLink,
  Menu,
  MenuItem
} from "@material-ui/core";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import menu_logo from "../menu_logo.png";
import { getNavConfig, NavConfig } from "../config";

const useStyles = makeStyles({
  root: {},
  appBar: {
    borderBottom: "solid 1px white"
  },
  grow: {
    flexGrow: 1
  },
  space: {
    minWidth: 100
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  phone: {
    fontWeight: 500
  }
});

const MenuButton: React.FC<{
  navConfig: NavConfig;
  navigate: (path: string) => void;
}> = ({ navConfig, navigate }) => {
  return (
    <Button color="inherit" onClick={() => navigate(navConfig.path)}>
      {navConfig.text}
    </Button>
  );
};

const MenuButtonWithChildren: React.FC<{
  navConfig: NavConfig;
  navigate: (path: string) => void;
}> = ({ navConfig, navigate }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button
        key={`menu_${navConfig.text}`}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {navConfig.text}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {navConfig.children &&
          navConfig.children.map(config => (
            <MenuItem
              onClick={() => {
                handleClose();
                navigate(config.path);
              }}
            >
              {config.text}
            </MenuItem>
          ))}
      </Menu>
    </React.Fragment>
  );
};

const DesktopNav: React.FC<{ navigate: (path: string) => void }> = ({
  navigate
}) => {
  const menuItems = getNavConfig("HOME").map((config, i) => (
    <React.Fragment>
      {!config.children && (
        <MenuButton key={`menu_${i}`} navConfig={config} navigate={navigate} />
      )}
      {config.children && (
        <MenuButtonWithChildren
          key={`menu_${i}`}
          navConfig={config}
          navigate={navigate}
        />
      )}
    </React.Fragment>
  ));

  return <Hidden smDown>{menuItems}</Hidden>;
};

interface HeaderProps extends RouteComponentProps<{}> {
  openSidebar: () => void;
}

const Header: React.FC<HeaderProps> = ({ openSidebar, history }) => {
  const classes = useStyles();
  const navigate = (path: string) => history.push(path);

  const sidebarButton = (
    <IconButton aria-label="Menu" onClick={openSidebar}>
      <MenuIcon />
    </IconButton>
  );

  const icon = (
    <Link to="/">
      <img src={menu_logo} alt="Menu" />
    </Link>
  );

  const phone = (
    <Typography color="inherit" className={classes.phone}>
      <MuiLink color="textPrimary" underline="hover" href="tel:+447896496293">
        07896 496 293
      </MuiLink>
    </Typography>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          {sidebarButton}
          {icon}
          <div className={classes.grow}></div>
          <DesktopNav navigate={navigate} />
          <Hidden smDown>
            <div className={classes.space}></div>
          </Hidden>
          {phone}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default withRouter(Header);
