import React from "react";
import { makeStyles, Typography, Fade } from "@material-ui/core";
import hero from "../images/hero.jpg";
import bg from "../images/black_bg.png";
import logo from "../images/logo.png";
import { BookQuoteButton, AboutButton } from "./actionButtons";

const useStyles = makeStyles({
  hero: {
    backgroundImage: `url(${hero})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "calc(100vh - 75px)",
  },
  strapBar: {
    width: "100%",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    backgroundImage: `url(${bg})`,
    textAlign: "center",
    paddingTop: "25px",
    paddingBottom: "25px",
  },
  strap: {
    paddingBottom: "15px",
    fontWeight: 400,
  },
  action: {
    textAlign: "center",
    margin: "5px",
  },
  buttons: {
    marginLeft: "5px",
    marginRight: "5px",
  },
});

const Hero: React.FC<{}> = () => {
  const classes = useStyles();

  return (
    <div className={classes.hero}>
      <Fade in timeout={1500}>
        <div className={classes.strapBar}>
          <img alt="logo" src={logo} />
          <Typography variant="h4" className={classes.strap}>
            Extensions, renovations and expert building maintenance
          </Typography>
          <Fade in timeout={4500}>
            <div>
              <span className={classes.buttons}>
                <BookQuoteButton />
              </span>
              <span className={classes.buttons}>
                <AboutButton />
              </span>
            </div>
          </Fade>
        </div>
      </Fade>
    </div>
  );
};

export default Hero;
