import React from "react";
import bedroom from "../images/banners/bedroom.jpg";
import { makeStyles, Paper, Typography, Grid, Link } from "@material-ui/core";
import Banner from "../components/banner";
import HomeIcon from "@material-ui/icons/Home";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import Layout from "../components/layout";

const useStyles = makeStyles((theme) => ({
  contact: {
    backgroundColor: theme.palette.text.primary,
    color: "black",
    padding: theme.spacing(6),
    textAlign: "center",
  },
  icon: {
    color: theme.palette.secondary.main,
  },
  message: {
    paddingBottom: theme.spacing(6),
  },
}));

const Contact: React.FC<{}> = () => {
  const classes = useStyles();
  return (
    <Layout>
      <div>
        <Banner text="CONTACT" background={bedroom} />
        <Paper className={classes.contact}>
          <div className={classes.message}>
            <Typography variant="h5">We would love to hear from you</Typography>
            <Typography>
              We pride ourselves on being approachable, any enquiry is welcome
              and we will endeavour to work with you to find <u>you</u> the
              right solution.
            </Typography>
          </div>
          <div>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={4}>
                <Typography variant="h5" display="block">
                  <EmailIcon className={classes.icon} />
                  {" Email"}
                </Typography>
                <div>
                  <Link
                    color="inherit"
                    underline="hover"
                    href="mailto:paul@pebuidingsolutions.co.uk"
                  >
                    paul@pebuidingsolutions.co.uk
                  </Link>
                </div>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography variant="h5" display="block">
                  <PhoneIcon className={classes.icon} />
                  {" Phone"}
                </Typography>
                <div>
                  <Link
                    color="inherit"
                    underline="hover"
                    href="tel:+447896496293"
                  >
                    07896 496 293
                  </Link>
                </div>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography variant="h5" display="block">
                  <HomeIcon className={classes.icon} />
                  {" Address"}
                </Typography>
                <div>
                  <Typography noWrap>
                    Based in
                    <br />
                    Walton-on-Thames
                    <br />
                    Surrey
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </div>
    </Layout>
  );
};

export default Contact;
