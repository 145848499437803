import React from "react";
import { makeStyles, Typography, Link } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    padding: theme.spacing(4)
  },
  fill: {
    flexGrow: 1
  }
}));

const Copyright: React.FC<{}> = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography>
        &copy;{` ${new Date().getFullYear()} PE Building Solutions`}
      </Typography>
      <div className={classes.fill}></div>
      <div>
        <Link href="https://www.facebook.com/PE-Building-Solutions-108118184107282/">
          <FacebookIcon fontSize="large" />
        </Link>
      </div>
    </div>
  );
};

export default Copyright;
