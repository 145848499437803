import React from "react";
import Header from "./components/header";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { indigo, orange } from "@material-ui/core/colors";
import ContactButton from "./components/contact";
import { Paper } from "@material-ui/core";
import Copyright from "./components/copyright";
import Footer from "./components/footer";
import Drawer from "./components/drawer";
import { HashRouter, Switch, Route } from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about";
import Contact from "./pages/contact";

const App: React.FC = () => {
  const peBuildingTheme = createMuiTheme({
    palette: {
      type: "dark",
      primary: indigo,
      secondary: orange,
    },
    typography: {
      fontWeightRegular: 300,
    },
  });

  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <ThemeProvider theme={peBuildingTheme}>
      <Paper>
        <HashRouter>
          <Header openSidebar={() => setOpen(true)} />
          <Drawer open={open} setOpen={setOpen} />

          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            {/* <Route path="/services/:serviceName" component={Services} /> */}
            {/* <Route path="/gallery" component={Gallery} /> */}
            <Route path="/contact" component={Contact} />
          </Switch>
        </HashRouter>

        <Footer />
        <Copyright />
        <ContactButton />
      </Paper>
    </ThemeProvider>
  );
};

export default App;
