import React from "react";
import { makeStyles } from "@material-ui/core";
import ScrollToTop from "./scrollToTop";

const useStyles = makeStyles({
  root: {},
});

interface LayoutProps {
  children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ScrollToTop />
      {children}
    </div>
  );
};

export default Layout;
