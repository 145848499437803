import React from "react";
import { makeStyles, Typography, Grid, Paper } from "@material-ui/core";
import { getServiceHighlights } from "../config";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6)
  },
  container: {
    flexGrow: 1,
    paddingTop: "20px",
    marginLeft: "10%",
    marginRight: "10%"
  },
  grid: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    marginTop: "15px"
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)"
  },
  title: {
    textAlign: "center",
    letterSpacing: 2
  },
  about: {},
  header: {
    textAlign: "center",
    letterSpacing: 3,
    marginBottom: "15px"
  },
  img: {
    width: "100%"
  }
}));

const Services: React.FC<{}> = () => {
  const classes = useStyles();

  const services = getServiceHighlights().map((config, i) => (
    <Grid item xs={12} sm={4} key={`service_${i}`}>
      <div>
        <Typography variant="h4" className={classes.title}>
          {config.title}
        </Typography>
        <img src={config.hero} className={classes.img} alt={config.title} />
        <div>
          <Typography className={classes.about}>{config.about}</Typography>
        </div>
      </div>
    </Grid>
  ));

  return (
    <Paper>
      <div className={classes.root}>
        <Typography variant="h2" className={classes.header}>
          Services
        </Typography>
        <div className={classes.container}>
          <Grid container spacing={6}>
            {services}
          </Grid>
        </div>
      </div>
    </Paper>
  );
};

export default Services;
