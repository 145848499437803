import React from "react";
import sink from "../images/banners/sink.jpg";
import bathroom from "../images/banners/bathroom.jpg";
import Banner from "../components/banner";
import { Paper, Typography, makeStyles } from "@material-ui/core";
import Layout from "../components/layout";
import { BookQuoteButton, GetQuoteButton } from "../components/actionButtons";

const useStyles = makeStyles((theme) => ({
  story: {
    padding: theme.spacing(6),
    "& p": {
      marginBottom: "20px",
    },
  },
  quoteContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  review: {
    backgroundColor: theme.palette.text.primary,
    color: "black",
    padding: theme.spacing(6),
    textAlign: "center",
  },
  quote: {
    textAlign: "center",
    color: "black",
  },
  highlight: {
    fontWeight: 400,
  },
  name: {},
  quoteButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const About: React.FC<{}> = () => {
  const classes = useStyles();

  return (
    <Layout>
      <div>
        <Banner text="ABOUT US" background={sink} />
        <Paper className={classes.story}>
          <Typography variant="h3">Our Story</Typography>
          <Typography variant="h5">
            Experienced general builders based in the East Molesey /
            Walton-on-Thames area of Surrey
          </Typography>
          <br />
          <Typography>
            Hi, my name is Paul and I have over {2023 - 2005} years experience
            working within the building trade. I started out as a builder's
            assistant whilst studying for my plumbing qualifications and now I
            have my own successful building company.
          </Typography>
          <Typography>
            Much of my work comes from previous customers and word of mouth. I
            take great pride in my work and in the relationships I develop with
            my customers, we work together as a team, with me putting their
            ideas into practice. Helping them improve their homes and work
            spaces.
          </Typography>
          <Typography>
            My experience covers all aspects of building work but I specialise
            in kitchens, bathrooms and extensions. I undertake work in both the
            private and commercial sector. I am particularly interested in eco
            builds and I keep abreast of all the latest building regulations,
            procedures and trends.
          </Typography>
          <Typography>
            One of my biggest projects was the development of my own home. I
            stripped the house down to the bare bones, re-confugured and
            transformed it into the lovely family home we now live in. My home
            encapsulates the pride and enjoyment that my work gives me.
          </Typography>
          <Typography>
            I have a small and reliable network of tradesmen that I might work
            with on larger projects. But at all times I would be the main
            contractor on your job.
          </Typography>
          <Typography>
            Please get in touch if you have a project in mind.
          </Typography>
          <GetQuoteButton />
        </Paper>

        <Paper className={classes.review}>
          <Typography variant="h5">Hear it from our customers</Typography>
          <Typography>
            Paul was fantastic from the quote stage right throughout the job.
            His work is second to none. I'm so pleased with the work he has done
            for us, the finish he has achieved is excellent. He has transformed
            our home. Thank you.
          </Typography>
          <Typography variant="h6" className={classes.highlight}>
            Complete downstairs renovation
          </Typography>
          <Typography className={classes.name}>
            <i>JG, Hersham, Surrey</i>
          </Typography>
        </Paper>

        <div className={classes.quote}>
          <Banner text="GET YOUR QUOTE" background={bathroom}>
            <div className={classes.quoteContainer}>
              <Typography variant="h5">
                Talk to us today to discuss your unique requirements
              </Typography>
              <BookQuoteButton />
            </div>
          </Banner>
        </div>
      </div>
    </Layout>
  );
};

export default About;
