import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    marginTop: "20px",
    marginBottom: "20px",
  },
});

interface ActionButtonProps extends RouteComponentProps {
  text: string;
  path: string;
}

const ActionButtonWithoutRouter: React.FC<ActionButtonProps> = ({
  text,
  path,
  history,
}) => {
  const classes = useStyles({ text: text });

  const navigate = (path: string) => history.push(path);

  return (
    <Button
      variant="contained"
      color="primary"
      className={`${classes.root}`}
      onClick={() => navigate(path)}
    >
      {text}
    </Button>
  );
};

const ActionButton = withRouter(ActionButtonWithoutRouter);

export const GetQuoteButton = () => (
  <ActionButton text="GET YOUR QUOTE" path="/contact" />
);
export const BookQuoteButton = () => (
  <ActionButton text="BOOK A QUOTE" path="/contact" />
);
export const ServicesButton = () => (
  <ActionButton text="OUR SERVICES" path="/services" />
);
export const AboutButton = () => <ActionButton text="ABOUT US" path="/about" />;

export default ActionButton;
