import React from "react";
import { Zoom, Fab, makeStyles, Tooltip, Link } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";

const useStyles = makeStyles(theme => ({
  fab: {
    position: "sticky",
    bottom: theme.spacing(2),
    left: theme.spacing(2)
  }
}));

const ContactButton = () => {
  const classes = useStyles();

  return (
    <Zoom in={true} timeout={500}>
      <Tooltip disableFocusListener placement="left" arrow title="Email us">
        <Fab
          aria-label={"Contact us"}
          color={"secondary"}
          className={classes.fab}
        >
          <Link
            href="mailto:paul@pebuildingsolutions.com?subject=Website Query&amp;body=Hi Paul"
            target="_blank"
            rel="noopener noreferrer"
            color="textPrimary"
          >
            <EmailIcon />
          </Link>
        </Fab>
      </Tooltip>
    </Zoom>
  );
};

export default ContactButton;
