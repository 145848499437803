import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: (props: BannerProps) => `url(${props.background})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingTop: "45px",
    paddingBottom: "45px",
    marginTop: "75px",
  },
  title: {
    width: "100%",
    textAlign: "center",
  },
}));

interface BannerProps {
  background: string;
  text: string;
  children?: React.ReactElement;
}

const Banner: React.FC<BannerProps> = ({ background, text, children }) => {
  const classes = useStyles({
    background: background,
    text: text,
  });

  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        {text}
      </Typography>
      {children}
    </div>
  );
};

export default Banner;
