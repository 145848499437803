import React from "react";
import {
  makeStyles,
  Drawer as MaterialDraw,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";

import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { NavConfig, getNavConfig } from "../config";

const useStyles = makeStyles((theme) => ({
  button: {
    textDecoration: "none",
  },
  menuText: {
    color: theme.palette.text.primary,
  },
  children: {
    paddingLeft: theme.spacing(4),
  },
}));

interface DrawerProps extends RouteComponentProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface MenuItem {
  text: string;
  Icon: React.ComponentType<SvgIconProps>;
  path: string;
}

const Drawer: React.FC<DrawerProps> = ({ open, setOpen }) => {
  const classes = useStyles({});

  const MenuButton: React.FC<NavConfig> = ({ path, text, Icon }) => {
    return (
      <Link to={path} className={classes.button}>
        <ListItem button>
          <ListItemIcon>
            <Icon color="secondary" />
          </ListItemIcon>
          <ListItemText primary={text} className={classes.menuText} />
        </ListItem>
      </Link>
    );
  };

  const createMenu = (configs: NavConfig[], padding?: boolean) => {
    const menuButtons = configs.map((config) => createMenuButton(config));
    const className = padding ? classes.children : "";

    return <List className={className}>{menuButtons}</List>;
  };

  const createMenuButton = (config: NavConfig) => {
    return (
      <React.Fragment>
        <MenuButton
          path={config.path}
          text={config.text}
          Icon={config.Icon}
          key={config.text}
        />
        {config.children && createMenu(config.children, true)}
      </React.Fragment>
    );
  };

  const menu = createMenu(getNavConfig());

  return (
    <MaterialDraw open={open} onClose={() => setOpen(false)}>
      <div
        tabIndex={0}
        role="button"
        onClick={() => setOpen(false)}
        onKeyDown={() => setOpen(false)}
      >
        {menu}
      </div>
    </MaterialDraw>
  );
};

export default withRouter(Drawer);
